<div class="col-sm-12 history-top text-right">
 <a [routerLink]="" (click)="redirectToUrl(masterloadidTrepp)">  
  <img src="../../../assets/Asset.png" class="cursor-pointer trepp-position"></a>
    <mat-icon class="icon-size cursor-pointer history-position" (click)="openDialog()">history</mat-icon>
</div>
<div class="container-fluid">
  <div class="text-center trigger-heading">
    <h1>{{dataSet?.propname}}</h1>
    <h2>{{dataSet?.dealName}} - {{dataSet?.poolNumber}}</h2>
  </div>

  <div class="form-group row">
    <label class="col-sm-4"></label>
    <div class="col-sm-3">
      <input type="text" class="form-control" id="date" [(ngModel)]="currentDate" disabled>
    </div>
    <div class="col-sm-4 icons-top">
      <mat-icon class="icon-size cursor-pointer snooze30-margin custom-class" (click)="snooze30('30')" [ngClass]="{ 'disabled': isDisabled30 }" matBadge="30" matBadgeColor="primary">alarm</mat-icon>
      <mat-icon class="icon-size cursor-pointer snooze90-margin custom-class" (click)="snooze90('90')" [ngClass]="{ 'disabled': isDisabled90 }" matBadge="90" matBadgeColor="primary">alarm</mat-icon>
      <mat-icon class="icon-size cursor-pointer snooze90-margin" (click)="unsnoozeTrigger()" matBadge="x" matBadgeColor="warn">alarm</mat-icon>
    </div>
  </div>

  <h3 class="text-left font-weight-bold">{{dataSet?.triggerType}}</h3>

  <form #triggerDetailsForm="ngForm">
    <div class="form-group row">
      <label for="lastFiredValue" class="col-sm-4 col-form-label">Last Fired Trigger Value</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="lastFiredValue"
          [value]="dataSet?.lastFiredValue==null? 'Undefined':dataSet?.lastFiredValue" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="lastFiredDate" class="col-sm-4 col-form-label">Last Fired Trigger Date</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="lastFiredDate"
          [value]="dataSet?.lastFiredDate==null? 'Undefined':dataSet?.lastFiredDate" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="lastFiredFinancialDate" class="col-sm-4 col-form-label">Last Fired Financial Date</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="lastFiredFinancialDate"
          [value]="dataSet?.lastFiredFinancialDate==null? 'Undefined':dataSet?.lastFiredFinancialDate" disabled>
      </div>
    </div>
  </form>

  <hr>

  <h3 class="text-left font-weight-bold">TRIGGER Notification</h3>

  <div class="form-group row">
    <label for="inputEmail3" class="col-sm-4 col-form-label">DY Threshold Current</label>
    <div class="col-sm-4 input-group-prepend">

      <input type="text" class="form-control text-right" name="triggervalue"
      droppable (onDrop)="dropIntoInput($event)" 
      [(ngModel)]="updateTriggerValue" droppable>
      <span class="input-group-text percent-height">%</span>
    </div>
    <div class="col-sm-4">
      <button class="submitButton" mat-flat-button color="primary" #button 
    [title]="button.disabled?'Kindly edit the form to enable submit':'click to submit'"
    (click)="showSuccess()">Submit Edit</button>
    </div>
  </div>

  <div class="form-group">
    <label class="font-weight-bold" draggable>Trigger Description</label>
    <span class="cursor-pointer" *ngFor="let item of resSplit">
      <span class="cursor-pointer" *ngIf="item.enableDrag" draggable [dragData]="item.text" class="drag-over cdkDrag">
        {{ item.text }} </span>
        <span class="cursor-pointer" *ngIf="!item.enableDrag"> {{ item.text }}</span>     
          </span>
  </div>

  <!-- <div class="text-center">
    <button class="submitButton" mat-flat-button color="primary" #button 
    [title]="button.disabled?'Kindly edit the form to enable submit':'click to submit'"
    (click)="showSuccess()">Submit Edit</button>
  </div> -->

</div>