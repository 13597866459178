import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UpdationServiceService } from 'src/app/services/updation-service.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ColDef,
  ColGroupDef,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  ICellRendererParams,
  MenuItemDef,
  RowClassParams,
  RowClassRules,
  RowStyle,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import 'ag-grid-enterprise';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { GetDataPath } from 'ag-grid-enterprise';
import { ActivatedRoute, Router } from '@angular/router';
import { BtnCellRenderer } from '../button-cell-renderer/button-cell-renderer';
import { DataTableService } from './datatable.service';
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class DatatableComponent implements OnInit {

  rowData: any[] = [];
  gridApi!: GridApi;
  gridColumnApi: any;
  static applyFilter: any;
  color: string = 'green';
  rowClassRules: RowClassRules | undefined;
  rowDataClicked1: any;
  triggerSource: any;
  loanNumber: any;
  isCustomTriggerShow: boolean = false;
  cellDisabled: any;
  loanKey: any;
  theme = 'ag-theme-balham'
  currentDate: string = '';
  masterloanTreppId: any;
  masterloanidtrepp: any;
  applyBackground: boolean = false;
  myDate = new Date();
  constructor(
    private updationService: UpdationServiceService,
    private triggerService: DataTableService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
      this.gridOptions.context = {
      thisComponent: this
    }
  }

  saveState() {
    localStorage.setItem('gridConfig', JSON.stringify(this.gridColumnApi.getColumnState()));
    console.log('column state saved');
  }
  restoreState() {
    if (!localStorage.getItem('gridConfig')) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }
    this.gridColumnApi.applyColumnState({
      state: JSON.parse(localStorage.getItem('gridConfig')|| '{}'),
      applyOrder: true,
    });
    console.log('column state restored');
  }
  resetState() {
    this.gridColumnApi.resetColumnState();
    console.log('column state reset');
  }

  public onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.restoreState();
    this.gridApi.redrawRows();
    this.gridApi.sizeColumnsToFit();
    this.gridApi.showLoadingOverlay();
    this.triggerService.getTriggersTableData().subscribe((data: any) => {
      this.rowData = data;
      this.gridApi.hideOverlay();
    });
  }

  public gridOptions: GridOptions = {
    sideBar: true,
    defaultColDef: {
      minWidth: 150,
      resizable: true,
      sortable: true,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filter: true,
      floatingFilter: true,
    },
    getRowId: (params: GetRowIdParams) => {
      return params.data.id;
    },
    rowClassRules: {
      'disable-row': function (params: any) {
        if (params.data)
          return params.data.disabled === 'Y';
        else
          return false;
      }
    },
    rowSelection: "single",
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>'
  }

  changeToggle(theme: any) {
    if (this.theme === 'ag-theme-balham') {
      this.theme = 'ag-theme-balham-dark';
      this.applyBackground = true;
    } else if (this.theme === 'ag-theme-balham-dark') {
      this.theme = 'ag-theme-balham';
      this.applyBackground = false;
    }
  }

  getRowClass(params: any) {
    if (params.data.disabled === 'Y') {
      return 'highlight';
    } else {
      return '';
    }
  }

  onBtnClick1(e: any) {
    this.rowDataClicked1 = e.rowData;
  }
  dataSource!: MatTableDataSource<any[]>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('sideNav') sideNav!: ElementRef;
  triggerID!: number;
  editablerowIndex!: number;
  isShowing = false;

  nestedCellClicked(params: any) {
    const selectedData = this.gridApi?.getSelectedRows();
    this.triggerID = selectedData[0].id;
    this.triggerSource = selectedData[0].triggerSource;
    this.updationService.triggerSource = selectedData[0].triggerSource;
    this.loanNumber = selectedData[0].poolNumber;
    this.masterloanidtrepp = selectedData[0].masterloanidtrepp;
    if (this.triggerID != null && this.triggerID != undefined && !Number.isNaN(this.triggerID) && this.triggerSource != "TreppAuto") {
      this.isShowing = true;
      this.isCustomTriggerShow = false;
      const updatedURL = String(this.triggerID);
      this.router.navigateByUrl(updatedURL);
    }
  }

  disableButtonRenderer(params: any) {
    return '<button (click)="onBtnClick($event)">Disable</button>'
  }

  changeColor(params: any) {
    let id = String(params.data.id);
    let firstNode = this.gridOptions.api?.getRowNode(id);
    let nodesToRefresh = [firstNode];
    this.refreshRowNodes(nodesToRefresh);
    this.color = this.color === "green" ? "pink" : "green"
  }

  refreshRowNodes(rowNodes: any) {
    let itemsToUpdate = rowNodes.map((row: { data: any; }) => row.data);
    this.gridOptions.api?.applyTransaction({ update: itemsToUpdate });
  }

  onBtnClick(event: any) {
    const rowIndex = event.rowIndex;
    this.rowData[rowIndex].disable = true;
    this.gridOptions.api?.refreshCells({ rowNodes: [event.node], columns: ['status'], });
  }

  onRowClick(event: any): void {
    this.editablerowIndex = event.rowIndex;
  }

  getRowStyle(params: RowClassParams) {
    if (params.data.enabled == true) {
      return { background: 'gray' } as RowStyle;
    }
    return undefined;
  }
  
  columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Loan Details',
      children: [
        {
          headerName: 'Status',
          field: 'status',
          sortable: true,
          filter: 'agMultiColumnFilter',
          //hide : true,
          onCellClicked: this.nestedCellClicked.bind(this),
          cellRenderer: (params: any): any => {
            if (params.value == 'Ok') {
              return '<i class="material-icons" style="color:green">radio_button_checked</mat-icon>';
            }
            if (params.value == 'Deferred') {
              return '<i class="material-icons" style="color:orange">radio_button_checked</mat-icon>';
            }
            if (params.value == 'Fired') {
              return '<i class="material-icons" style="color:red">radio_button_checked</mat-icon>';
            }
          }
        },
        {
          headerName: 'Pool Number',
          field: 'poolNumber',
          sortable: true,
          filter: 'agMultiColumnFilter',
          // filterParams: defaultFilterParams,
          columnGroupShow: 'closed',
          // onCellClicked:this.getContextMenuItems.bind(this)
        },
        {
          headerName: 'Loan Name',
          field: 'propname',
          sortable: true,
          filter: 'agMultiColumnFilter',
        },
        {
          headerName: 'Deal Name',
          field: 'bloombergName',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
        },
        {
          headerName: 'Analyst Name',
          field: 'surveillanceAnalyst',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
        },
        {
          headerName: 'Rialto Status',
          field: 'rialtoLoanStatus',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
        },
        {
          headerName: 'Deal Type',
          field: 'dealType',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
        },

      ],
    },
    {
      headerName: 'Trigger Details',
      children: [
        {
          headerName: 'Trigger Name',
          field: 'name',
          sortable: true,
          filter: 'agMultiColumnFilter',
          hide: true,
          cellRenderer: this.nameRenderer,
          columnGroupShow: 'closed',
        },
        {
          headerName: 'Trigger ID',
          field: 'id',
          sortable: true,
          filter: 'agMultiColumnFilter',
        },
        {
          headerName: 'Trigger Value',
          field: 'triggerValue',
          sortable: true,
          filter: 'agMultiColumnFilter',
          cellRenderer: this.triggerValueRenderer
        },
        {
          headerName: 'Trigger Type',
          field: 'triggerType',
          sortable: true,
          filter: 'agMultiColumnFilter',
          cellRenderer: this.triggerTypeRenderer
        },
        {
          headerName: 'Trigger Source',
          field: 'triggerSource',
          sortable: true,
          filter: 'agMultiColumnFilter',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Trigger Date',
          field: 'triggerDateValue',
          sortable: true,
          filter: 'agMultiColumnFilter',
          cellRenderer: this.triggerSourceRenderer,
          columnGroupShow: 'closed',
        },
        {
          headerName: 'Trigger Value',
          field: 'triggerValue',
          sortable: true,
          filter: 'agMultiColumnFilter',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Trigger Additional',
          field: 'triggerAdditional',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Fired Value',
          field: 'lastFiredValue',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Fired Date',
          field: 'lastFiredDate',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Financial Date',
          field: 'lastFiredFinancialDate',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
        },
        {
          headerName: 'Prefix Value',
          field: 'prefixTriggerValue',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'SQFT',
          field: 'sqft',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'NRA',
          field: 'nra',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Position',
          field: 'position',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Day Snooze',
          field: 'snoozeDaysLeft',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.daysSnoozeRenderer
        },
        {
          headerName: 'Manual ModifiedDate',
          field: 'manualModifiedDate',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Manual ModifiedBy',
          field: 'manualModifiedBy',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.triggerSourceRenderer
        },
        {
          headerName: 'Trigger Date Value',
          field: 'triggerDateValue',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
        },
        {
          headerName: 'Expiration Date',
          field: 'expirationDate',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.expirationDateRenderer
        },
        {
          headerName: 'Last Fired Date',
          field: 'lastFiredDate',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRenderer: this.lastFiredDateRenderer
        },
        {
          headerName: 'Actions',
          field: 'disabled',
          sortable: true,
          filter: 'agMultiColumnFilter',
          columnGroupShow: 'closed',
          cellRendererFramework: BtnCellRenderer,
          cellRendererParams: {
            onClick: this.onBtnClick1.bind(this),
          }
        }
      ],
    },
  ]

  apiResponse!: any[];
  idFromURL: any;

  ngOnInit(): void {
    this.updationService.loading$.subscribe(loading => {
      if (loading) {
        this.gridApi?.showLoadingOverlay();
      } else {
        this.gridApi?.hideOverlay();
      }
    });

    //this is a part for the router reading
    this.route.paramMap.subscribe(params => {
      this.triggerID = Number(params.get('id'));
      if (Number(params.get('id'))) {
        this.router.navigate(['', this.triggerID])
        this.isShowing = true;
        this.isCustomTriggerShow = false;
      }
    });

    this.updationService.selectedActionRow$.subscribe((data: any) => {
      if (this.gridApi != undefined) {
        var id = String(data.body);
        var rowNode = this.gridOptions.api?.getRowNode(id)!;
        this.cellDisabled = rowNode.data.disabled;
        rowNode.setDataValue('actions', this.cellDisabled);
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      }
    });

    this.updationService.selectedProduct$.subscribe((data: any) => {
      if (this.gridApi != undefined) {
        var id = String(data.id);
        var rowNode = this.gridOptions.api?.getRowNode(id)!;
        var newDate = data.triggerDateValue;
        rowNode.setDataValue('triggerDateValue', newDate);
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      }
    });

    this.updationService.selectedDSCR$.subscribe((data: any) => {
      if (this.gridApi != undefined) {
        var id = String(data.id);
        var rowNode = this.gridOptions.api?.getRowNode(id)!;
        var newDate = data.triggerValue;
        var daysLeft = data.snoozeDaysLeft;
        rowNode.setDataValue('triggerValue', newDate);
        rowNode.setDataValue('snoozeDaysLeft', daysLeft);
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      }
    });

    this.updationService.selectedNOI$.subscribe((data: any) => {
      if (this.gridApi != undefined) {
        var id = String(data.id);
        var rowNode = this.gridOptions.api?.getRowNode(id)!;
        var newDate = data.triggerValue;
        var daysLeft = data.snoozeDaysLeft;
        rowNode.setDataValue('triggerValue', newDate);
        rowNode.setDataValue('snoozeDaysLeft', daysLeft);
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      }
    });

    this.updationService.selectedDY$.subscribe((data: any) => {
      if (this.gridApi != undefined) {
        var id = String(data.id);
        var rowNode = this.gridOptions.api?.getRowNode(id)!;
        var newDate = data.triggerValue;
        var daysLeft = data.snoozeDaysLeft;
        rowNode.setDataValue('triggerValue', newDate);
        rowNode.setDataValue('snoozeDaysLeft', daysLeft);
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      }
    });

    this.updationService.selectedAllCMBS$.subscribe((data: any) => {
      if (data.length > 0) {
        this.rowData = data;
        this.gridApi.setRowData(this.rowData);
        this.gridApi.refreshCells({ force: true });
        this.router.navigate(['/CMBS']);
        this.updationService.stopLoading();
      }
    });

    this.updationService.selectedAllTriggers$.subscribe((data: any) => {
      if (data.length > 0) {
        this.rowData = data;
        this.gridApi.setRowData(this.rowData);
        this.gridApi.refreshCells({ force: true });
        this.router.navigate(['/home']);
        this.updationService.stopLoading();
      }
    });

    this.updationService.selectedAllCLO$.subscribe((data: any) => {
      if (data.length > 0) {
        this.rowData = data;
        this.gridApi.setRowData(this.rowData);
        this.gridApi.refreshCells({ force: true });
        this.router.navigate(['/CLO']);
        this.updationService.stopLoading();
      }
    });

    this.updationService.selectedCustom$.subscribe((data: any) => {
      let refreshValue: Boolean = true;
      if (this.gridApi != undefined) {
        switch (this.updationService.globalMenuName) {
          case "allCMBS":
            this.gridApi.showLoadingOverlay();
            this.triggerService.getAllCMBSData().subscribe((res: any) => {
              if (res && res.length > 0) {
                this.rowData = res;
                this.gridApi.hideOverlay();
              }
            });
            break;
          case "allCLO":
            this.gridApi.showLoadingOverlay();
            this.triggerService.getAllCLOData().subscribe((res: any) => {
              if (res && res.length > 0) {
                this.rowData = res;
                this.gridApi.hideOverlay();
              }
            });
            break;
          case "globalTrigger":
            this.gridApi.showLoadingOverlay();
            this.triggerService.getGlobalTriggersData().subscribe((res: any) => {
              if (res && res.length > 0) {
                this.rowData = res;
                this.gridApi.hideOverlay();
              }
            });
            break;
          default:
            this.gridApi.showLoadingOverlay();
            this.triggerService.getMyTriggersData().subscribe((res: any) => {
              if (res && res.length > 0) {
                this.rowData = res;
                this.gridApi.hideOverlay();
              }
            });
        }
        this.gridApi.setRowData(this.rowData);
        this.gridApi.refreshCells({ force: true });
      }
    });

    this.updationService.applyFilterIntoGrid$.subscribe((data: any) => {
      if (this.gridApi != undefined) {
        var polNumber = data.poolNumber;
        var countryFilterComponent = this.gridApi.getFilterInstance('poolNumber')!;
        countryFilterComponent.setModel({
          filterModels: [
            {
              type: 'set',
              filter: polNumber,
            },
          ],
        });
        this.gridApi.onFilterChanged();
      }
    });

    this.updationService.selectedCustomTrigger$.subscribe((data: any) => {
      if (this.gridApi != undefined) {
        var id = String(data.id);
        var rowNode = this.gridOptions.api?.getRowNode(id)!;
        var newDate = data.triggerDateValue;
        rowNode.setDataValue('triggerDateValue', newDate);
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      }
    });

    this.updationService.selectedGlobalTriggers$.subscribe((res: any) => {
      if (res && res.length > 0) {
        this.rowData = res;
        this.gridApi.setRowData(this.rowData);
        this.gridApi.refreshCells({ force: true });
        this.router.navigate(['/globalTrigger']);
        this.updationService.stopLoading();
      }
    });
  }

  valueToClassRenderer(params: any) {
    if (params.data.disabled === 'Y') {
      return '<div class="highlight" id ="highlight1"><i class="material-icons" style="color:red">toggle_off</i></div>';
    } else { return '<div class="highlight" id ="highlight1"><i class="material-icons" style="color:green">toggle_on</i></div>' }
  }

  nameRenderer(params: any) {
    let eIconGui = document.createElement('span');
    if (params.value == null || params.value == '')
      return '';
    return eIconGui.innerHTML = '<em class="material-icons">person</em>' + ' ' + params.value;

  }

  triggerValueRenderer(params: ICellRendererParams) {

    let eIconGui = document.createElement('span');
    if (params.value == null || params.value == '')
      return '';
    return eIconGui.innerHTML = '<em class="material-icons">data_thresholding </em>' + ' ' + params.value;
  }

  triggerTypeRenderer(params: ICellRendererParams) {

    let eIconGui = document.createElement('span');
    if (params.value == null || params.value == '')
      return '';
    return eIconGui.innerHTML = '<em class="material-icons">apartment</em>' + ' ' + params.value;
  }

  expirationDateRenderer(params: ICellRendererParams) {

    let eIconGui = document.createElement('span');
    if (params.value == null || params.value == '')
      return '';
    return eIconGui.innerHTML = '<em class="material-icons">date_range</em>' + ' ' + params.value;
  }

  lastFiredDateRenderer(params: ICellRendererParams) {

    let eIconGui = document.createElement('span');
    if (params.value == null || params.value == '')
      return '';
    return eIconGui.innerHTML = '<em class="material-icons">event</em>' + ' ' + params.value;
  }

  triggerSourceRenderer(params: ICellRendererParams) {
    let eIconGui = document.createElement('span');
    if (params.value == null || params.value == '')
      return '';
    return eIconGui.innerHTML = '<em class="material-icons">dataset</em>' + ' ' + params.value;
  }

  daysSnoozeRenderer(params: ICellRendererParams) {
    let eIconGui = document.createElement('span');
    if (params.value == null || params.value == '')
      return '';
    return eIconGui.innerHTML = '<em class="material-icons">snooze</em>' + ' ' + params.value;
  }

  flag = true;

  dataSet(
    bloomberryID: string,
    loanKey: string,
    trigger_id: string,
    tname: string,
    tval: number,
    description: string,
    highlighted: string[]
  ) {
  }


  uniqueAnalyst!: any[];

  uniqueArrayPopulate(data: any[]) {
    this.uniqueAnalyst = Array.from(
      new Set(data.map((item: any) => item.surveillanceAnalyst))
    );
  }
  public getDataPath: GetDataPath = function (data) {
    return data.loanTriggerLimitedSettings;
  };
  public CustomTrigger(params: any): void {
    const selectedData1 = this.gridApi?.getSelectedRows();
    const selectedData = params;
    this.isShowing = false;
    this.isCustomTriggerShow = true;
    this.triggerID = selectedData.id;
    this.loanKey = selectedData.loanKey;
    this.loanNumber = selectedData.poolNumber;
  }

  getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    var result: (string | MenuItemDef)[] = [
      {
        name: 'Add New Custom Trigger',
        action: () => {
          params.context.thisComponent.CustomTrigger(params.node?.data);
        },
        cssClasses: ['redFont', 'bold'],
      },
      'separator',
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'resetColumns',
      'separator',
      'export'
    ];
    return result;
  }

}

