<div class="col-sm-12 history-top text-right">
  <a [routerLink]="" (click)="redirectToUrl(masterloadidTrepp)">  
    <img src="../../../assets/Asset.png" class="cursor-pointer trepp-position"></a>
      <mat-icon class="icon-size cursor-pointer history-position" (click)="openDialog()">history</mat-icon>
 </div>
 <div class="container-fluid">
  <div class="text-center">
    <h1>{{dataSet?.name}}</h1>
    <h2>{{dataSet?.propname}}</h2>
    <h3>{{dataSet?.dealName}} - {{dataSet?.poolNumber}}</h3>
  </div>

  <div class="form-group row">
    <label class="col-sm-4"></label>
    <div class="col-sm-3">
      <input type="text" class="form-control" id="date" [(ngModel)]="currentDate" disabled>
    </div>
    <div class="col-sm-4 icons-top">
      <mat-icon class="icon-size cursor-pointer snooze30-margin custom-class" (click)="snooze30('30')" [ngClass]="{ 'disabled': isDisabled30 }" matBadge="30" matBadgeColor="primary">alarm</mat-icon>
      <mat-icon class="icon-size cursor-pointer snooze90-margin custom-class" (click)="snooze90('90')" [ngClass]="{ 'disabled': isDisabled90 }" matBadge="90" matBadgeColor="primary">alarm</mat-icon>
      <mat-icon class="icon-size cursor-pointer snooze90-margin" (click)="unsnoozeTrigger()" matBadge="x" matBadgeColor="warn">alarm</mat-icon>
    </div>
  </div>

  <h3 class="text-left font-weight-bold">{{dataSet?.triggerType}} LEASE TRIGGER</h3>

  <form #triggerDetailsForm="ngForm">
    <div class="form-group row">
      <label for="tenantName" class="col-sm-4 col-form-label">Tenant Name: </label>
      <div class="col-sm-7">
        <input type="text" class="form-control"
          [value]="dataSet?.name==null? 'Undefined':dataSet?.name" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="nra" class="col-sm-4 col-form-label">NRA: </label>
      <div class="col-sm-7">
        <input type="text" class="form-control"
          [value]="dataSet?.nra==null? 'Undefined':dataSet?.nra" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="sqft" class="col-sm-4 col-form-label">Square Feet: </label>
      <div class="col-sm-7">
        <input type="sqft" class="form-control"
          [value]="dataSet?.sqft==null? 'Undefined':dataSet?.sqft" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="expDate" class="col-sm-4 col-form-label">Expiration Date: </label>
      <div class="col-sm-7">
        <input type="expDate" class="form-control"
          [value]="dataSet?.expirationDate==null? 'Undefined':dataSet?.expirationDate" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="position" class="col-sm-4 col-form-label">Position: </label>
      <div class="col-sm-7">
        <input type="position" class="form-control"
          [value]="dataSet?.position==null? 'Undefined':dataSet?.position" disabled>
      </div>
    </div>
  </form>

  <hr>

  <h3 class="text-left font-weight-bold">TRIGGER Notification</h3>

  <div class="form-group row">
    <label for="inputEmail3" class="col-sm-4 col-form-label" *ngIf="custom == false">Current Trigger Value: </label>
    <label for="inputEmail3" class="col-sm-4 col-form-label" *ngIf="custom">Notify On: </label>
    <div class="col-sm-4 input-group-prepend" *ngIf="custom == false">
      <input type="text" class="form-control text-right" name="triggervalue"
      droppable (onDrop)="dropIntoInput($event)" 
      [(ngModel)]="updateTriggerValue" droppable>
      <!-- <input type="text" class="form-control text-right" name="" [ngModel]="dataSet?.triggerDateValue" 
      (ngModelChange)="dataSet.triggerDateValue = $event"> -->
    </div>

    <div class="col-sm-4" *ngIf="custom">
      <mat-form-field appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker"
        [ngModel]="dataSet?.expirationDate" 
        (ngModelChange)="dataSet.expirationDate = $event"
          [ngModelOptions]="{standalone: true}">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [ngModel]="dataSet?.expirationDate" 
        (ngModelChange)="dataSet.expirationDate = $event" (selectedChanged)="onDate($event)" [ngModelOptions]="{standalone: true}"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-sm-4" style="padding: 1% 0% 0 8%;">
      <button [title]="custom?'Click to enable manual entry!':'Click to enable DatePicker entry!'" 
      mat-raised-button (click)="custom=!custom">Custom</button>
    </div>
  </div>

  <div class="form-group">
    <label class="font-weight-bold" draggable>Trigger Description</label>
    <span class="cursor-pointer" *ngFor="let item of resSplit">
      <span class="cursor-pointer" *ngIf="item.enableDrag" draggable [dragData]="item.text" class="drag-over cdkDrag">
        {{ item.text }} </span>
        <span class="cursor-pointer" *ngIf="!item.enableDrag"> {{ item.text }}</span>     
          </span>
  </div>

  <div class="text-center">
    <button class="submitButton" mat-flat-button color="primary"  #button 
    [title]="button.disabled?'Kindly edit the form to enable submit':'click to submit'"
    (click)="showSuccess(); indexTrack();">Submit Edit</button>
  </div>

</div>
